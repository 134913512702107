<script>
import compact from 'lodash/compact';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import keys from 'lodash/keys';
import map from 'lodash/map';
import find from 'lodash/find';
import capitalize from 'lodash/capitalize';
import uniq from 'lodash/uniq';
import moment from 'moment-timezone';
import { mapActions, mapMutations, mapState } from 'vuex';
import { nextTick } from 'vue';
import { BUTTON_TYPES, MuiDatePicker } from '@emobg/vue-base';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiSelect,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import {
  camelCaseKeys,
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatUtc,
  TIME_ZONE,
} from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  GenericModalComponent,
  SaveButtonDebounced,
} from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { isNumeric } from '../utils/validators';
import DocumentSelector from './components/DocumentSelectorComponent';
import { formatUuid } from '../utils';
import Currency from '../utils/currency';
import { filterByContract } from '../algoliaFilters/filterByContract';
import { CHARGES, PERIODS, SERVICE_TYPES } from '../constants';
import { SCOPES as INVOICE_PLAN_SCOPES } from '../InvoicePlan/store/InvoicePlanModule';

export default {
  name: 'ServiceFeesModal',
  components: {
    CancelButton,
    DocumentSelector,
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiDatePicker,
    MuiInputText,
    MuiSelect,
    MuiTextarea,
    MuiValidationWrapper,
    SaveButtonDebounced,
  },
  directives: {
    Validate,
  },
  props: {
    serviceFee: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      formData: null,
      isRefundWarningVisible: false,
      isRefund: false,
      inputs: {
        name: null,
        description: null,
        invoiceDocument: null,
        serviceType: null,
        periodType: null,
        affectedVehicles: null,
        invoicePlanUuid: null,
        units: null,
        salesOrderUuid: null,
        activeStartDate: null,
        activeEndDate: null,
        amount: null,
        percentage: null,
        vat: null,
        relatedInvoiceUuid: null,
      },
      invoiceDocuments: [],
      isPercentage: false,

      initActiveStartDate: null,
      initActiveEndDate: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleetInvoicing.invoicePlan, {
      issuedInvoices: state => state[INVOICE_PLAN_SCOPES.issuedInvoices].data.data,
      issuedInvoicesLoading: state => state[INVOICE_PLAN_SCOPES.issuedInvoices].STATUS.LOADING,
    }),
    ...mapState(DOMAINS_MODEL.fleetInvoicing.serviceFees, {
      isLoading: state => state.STATUS.LOADING,
      isLoaded: state => state.STATUS.LOADED,
      isError: state => state.STATUS.ERROR,
      error: state => state.error,
    }),
    ...mapState(DOMAINS_MODEL.fleetInvoicing.serviceTypes, {
      serviceTypes: state => state.serviceTypes.data.data,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => get(state, 'operators.active.timezone', TIME_ZONE.default),
    }),
    isAffectingToASalesOrder() {
      return this.inputs.affectedVehicles === get(find(CHARGES, ['value', 'sales_order']), 'value');
    },
    servicesList() {
      return keys(this.serviceTypes)
        .map(service => ({
          value: service,
          label: this.serviceTypes[service],
        }));
    },
    isPrivateUse() {
      return this.inputs.serviceType === SERVICE_TYPES.managementFeeForPrivateUse;
    },
    isNonRevenue() {
      return this.inputs.serviceType === SERVICE_TYPES.nonRevenueRefundForPrivateUse;
    },
    invoicePlanUuidIfRefund() {
      return this.inputs.serviceType === SERVICE_TYPES.refund && this.inputs.invoicePlanUuid;
    },
    showUnitsField() {
      return this.inputs.serviceType !== SERVICE_TYPES.managementFeeForPrivateUse
        && this.inputs.serviceType !== SERVICE_TYPES.nonRevenueRefundForPrivateUse
        && this.inputs.affectedVehicles === 'none';
    },
    filteredPeriods() {
      const allPeriods = keys(this.PERIODS)
        .map(period => ({
          value: period,
          label: capitalize(this.PERIODS[period]),
        }));

      return (this.inputs.serviceType === SERVICE_TYPES.refund)
        ? [{
          value: PERIODS.once,
          label: capitalize(this.PERIODS.once),
        }]
        : allPeriods;
    },
  },
  watch: {
    isLoaded(isLoaded) {
      if (isLoaded) {
        this.$emit('refresh-table');
        this.displaySuccessMessage();
        this.closeModal();
      }
    },
    isError(isError) {
      if (isError) {
        const errorMessage = this.error.message || FALLBACK_MESSAGE.error;
        this.displayError(errorMessage);
      }
    },
    isPercentage(newPercentage) {
      if (newPercentage) {
        this.inputs.amount = null;
      } else {
        this.inputs.percentage = null;
      }
    },
    'inputs.serviceType': {
      handler() {
        this.isRefundWarningVisible = false;
        if (this.inputs.serviceType === SERVICE_TYPES.refund) {
          this.isRefundWarningVisible = true;
          this.inputs.periodType = PERIODS.once;
          this.isRefund = true;
        } else {
          this.isRefundWarningVisible = false;
          this.isRefund = false;
        }
      },
    },
    async invoicePlanUuidIfRefund(invoicePlanUuid) {
      this.inputs.relatedInvoiceUuid = null;

      if (invoicePlanUuid) {
        nextTick(() => {
          this.$refs.relatedInvoice.$el.MuiValidationManager.reset();
        });
        await this.getIssuedInvoices(invoicePlanUuid);
      } else {
        this.setInvoicePlanData({
          scope: INVOICE_PLAN_SCOPES.issuedInvoices,
          value: [],
        });
      }
    },
  },
  created() {
    this.CHARGES = CHARGES;
    this.PERIODS = PERIODS;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.BUTTON_TYPES = BUTTON_TYPES;

    this.contractUuid = get(this, '$route.params.contractUuid');

    this.formatUuid = formatUuid;
    this.isNumeric = isNumeric;
    this.filterByContract = filterByContract;

    if (this.serviceFee) {
      this.inputs = this.normalizeFromAlgolia(this.serviceFee);

      this.initActiveStartDate = this.inputs.activeStartDate;
      this.initActiveEndDate = this.inputs.activeEndDate;
    }

    this.getServiceTypes(get(this, '$route.params.companyUuid'));
    this.getInvoiceDocuments();
  },
  methods: {
    capitalize,
    map,
    ...mapActions(DOMAINS_MODEL.fleetInvoicing.serviceTypes, [
      'getServiceTypes',
    ]),
    ...mapActions(DOMAINS_MODEL.fleetInvoicing.serviceFees, [
      'saveServiceFee',
    ]),
    ...mapMutations(DOMAINS_MODEL.fleetInvoicing.invoicePlan, {
      setInvoicePlanData: 'setData',
    }),
    ...mapActions(DOMAINS_MODEL.fleetInvoicing.invoicePlan, [
      'getIssuedInvoices',
    ]),
    formatRelatedInvoiceDate(date) {
      return date ? formatUtc(date, DATE_FORMAT.dob, this.operatorTimezone) : this.FALLBACK_MESSAGE.noDate;
    },
    netAmountAddDecimal() {
      const originalValue = this.inputs.amount.replace(',', '.');
      this.inputs.amount = String(Number(originalValue).toFixed(2));
    },
    displayError(errorMessage) {
      this.$notify({
        message: errorMessage,
        textAction: 'OK',
        type: NOTIFICATION_TYPES.error,
      });
    },
    normalizeFromAlgolia({ ...algoliaServiceFee }) {
      const serviceFee = camelCaseKeys(algoliaServiceFee);
      const feePercentage = get(serviceFee, 'fee.percentage');
      serviceFee.invoicePlanUuid = get(serviceFee, 'invoicePlan.uuid');
      const netAmount = get(serviceFee, 'fee.netAmount', NaN);
      if (!isNaN(netAmount)) {
        serviceFee.amount = Math.abs(Currency.parse(netAmount)).toFixed(2);
      }
      serviceFee.invoiceDocument = serviceFee.document;
      serviceFee.activeEndDate = moment(serviceFee.activeEndDate, DATE_FORMAT.date, this.operatorTimezone);
      serviceFee.activeStartDate = moment(serviceFee.activeStartDate, DATE_FORMAT.date, this.operatorTimezone);
      if (feePercentage) {
        this.isPercentage = true;
        serviceFee.percentage = feePercentage;
      }
      return serviceFee;
    },
    displaySuccessMessage() {
      this.$notify({
        message: 'Service fee successfully saved',
        textAction: 'OK',
      });
    },

    formatStartDate(date) {
      this.inputs.activeStartDate = date._d;
    },

    formatEndDate(date) {
      this.inputs.activeEndDate = date._d;
    },

    submitServiceFee(contractUuid, {
      activeEndDate,
      activeStartDate,
      affectedVehicles,
      amount,
      percentage,
      description,
      invoicePlanUuid,
      name,
      periodType,
      salesOrderUuid,
      serviceType,
      uuid,
      units,
      vat,
      relatedInvoiceUuid,
    }) {
      const serviceFeePayload = {
        serviceFeeUuid: get(this.serviceFee, 'uuid'),
        request: {
          activeEndDate: formatUtc(activeEndDate, DATE_FORMAT.date, this.operatorTimezone),
          activeStartDate: formatUtc(activeStartDate, DATE_FORMAT.date, this.operatorTimezone),
          affectedVehicles,
          amount: this.isPercentage || this.isNonRevenue ? null : Currency.serialize(amount),
          percentage,
          contractUuid,
          description,
          document: this.inputs.invoiceDocument,
          invoicePlanUuid,
          name,
          periodType,
          salesOrderUuid,
          serviceType,
          units: this.showUnitsField ? units : null,
          uuid,
          vat,
          relatedInvoiceUuid,
        },
      };
      this.saveServiceFee(serviceFeePayload);
    },
    async onSubmit() {
      this.submitServiceFee(this.contractUuid, this.inputs);
    },
    async getInvoiceDocuments() {
      try {
        const { hits } = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.fpServiceFees, {
          filters: `contract_uuid: ${this.contractUuid}`,
          hitsPerPage: 100,
        });

        const allDocuments = map(hits, ({ document }) => document);
        const uniqueDocuments = uniq(allDocuments);
        const validDocuments = compact(uniqueDocuments);
        const documentSelectorDocuments = [
          '',
          ...validDocuments,
        ];
        this.invoiceDocuments = map(documentSelectorDocuments, document => ({
          value: document,
          label: document,
        }));
      } catch (error) {
        this.$throwError(error);
      }
    },
    closeModal() {
      this.$emit('close-modal');
    },
  },
};
</script>

<template>
  <ui-form @submitform="onSubmit">
    <MuiValidationWrapper>
      <template slot-scope="{ areAllValid }">
        <GenericModalComponent
          v-bind="$attrs"
          :header="{ isClosable: true }"
          :title="serviceFee ? 'Edit service fee' : 'Add service fee'"
          is-open
          @modal-closed="closeModal"
        >
          <template #alerts>
            <ui-alert
              v-if="isRefundWarningVisible"
              :color="COLORS.warning"
              :icon="ICONS.alertFull"
              dismissible
              class="d-block mb-5"
              @dismissAlert="isRefundWarningVisible = false"
            >
              You've selected "Refund". Make sure this is the action you want to do
            </ui-alert>
          </template>
          <template #body>
            <div>
              <p>
                Configure the service invoice lines as they are going to appear in the
                invoice
              </p>
              <div class="row mt-4">
                <div class="col-md-6 mb-4">
                  <MuiSelect
                    v-model="inputs.serviceType"
                    v-validate="{
                      isRequired: true,
                    }"
                    :options="servicesList"
                    name="serviceType"
                    option-label="label"
                    option-value="value"
                    label="Service type*"
                    class="w-100"
                    placeholder="Select a service type"
                  />
                </div>
                <div class="col-md-6 mb-4">
                  <MuiInputText
                    v-model="inputs.name"
                    v-validate.blur="{
                      isRequired: true,
                    }"
                    label="Service name*"
                    name="name"
                    class="w-100"
                    placeholder="Enter a service name*"
                  />
                </div>
                <div class="col-md-6 mb-4">
                  <MuiDatePicker
                    v-validate="{
                      isRequired: true,
                    }"
                    :date="initActiveStartDate"
                    :size="SIZES.small"
                    class="w-100"
                    label="Active start date*"
                    skiptime
                    data-test-id="service_fees-start_date"
                    @update:date="formatStartDate"
                  />
                </div>
                <div class="col-md-6 mb-4">
                  <MuiDatePicker
                    v-validate="{
                      isRequired: true,
                    }"
                    :date="initActiveEndDate"
                    :size="SIZES.small"
                    class="w-100"
                    label="Active end date*"
                    skiptime
                    data-test-id="service_fees-end_date"
                    @update:date="formatEndDate"
                  />
                </div>
                <div class="col-12 mb-4">
                  <MuiTextarea
                    v-model="inputs.description"
                    v-validate.blur="{
                      isRequired: true,
                    }"
                    label="Description*"
                    name="description"
                    placeholder="Enter a description"
                  />
                </div>
                <div class="col-12 mb-4">
                  <MuiSelect
                    v-model="inputs.affectedVehicles"
                    v-validate="{
                      isRequired: true,
                    }"
                    :options="CHARGES"
                    name="affectedVehicles"
                    class="w-100"
                    label="Affected vehicles*"
                    placeholder="Select affected vehicles"
                  />
                </div>
                <div class="col-6 mb-4">
                  <MuiAlgoliaSelect
                    v-model="inputs.invoicePlanUuid"
                    v-validate="{
                      isRequired: true,
                    }"
                    :title="({billing_reference}) => billing_reference"
                    :filters="filterByContract(contractUuid)"
                    :index="ALGOLIA_INDEXES.fpInvoicePlans"
                    label="Invoice plan*"
                    name="invoicePlanUuid"
                    path-value="uuid"
                    class="w-100"
                  />
                </div>
                <div class="col-6 mb-4">
                  <DocumentSelector
                    v-if="invoiceDocuments.length && !isRefund"
                    v-model="inputs.invoiceDocument"
                    :invoice-documents="invoiceDocuments"
                    label="Document"
                  />
                  <DocumentSelector
                    v-else-if="invoiceDocuments.length && isRefund"
                    v-model="inputs.invoiceDocument"
                    v-validate="{
                      isRequired: true,
                    }"
                    :invoice-documents="invoiceDocuments"
                    label="Document*"
                  />
                  <MuiInputText
                    v-else
                    v-model="inputs.invoiceDocument"
                    name="invoiceDocument"
                    label="Document"
                    class="w-100"
                    placeholder="Select or add a document"
                    required
                  />
                </div>
                <div
                  v-if="invoicePlanUuidIfRefund"
                  class="col-12 mb-4 relative"
                >
                  <ui-loader
                    v-if="issuedInvoicesLoading"
                    absolute
                  />
                  <MuiSelect
                    ref="relatedInvoice"
                    v-model="inputs.relatedInvoiceUuid"
                    v-validate="{
                      isRequired: true,
                    }"
                    :options="map(
                      issuedInvoices,
                      issuedInvoice => ({
                        label: `${issuedInvoice.reference} - ${formatRelatedInvoiceDate(issuedInvoice.date)}`,
                        value: issuedInvoice.uuid,
                      })
                    )"
                    label="Related invoice*"
                    class="w-100"
                    placeholder="Select related invoice"
                    name="relatedInvoiceUuid"
                  />
                </div>
                <div class="col-12">
                  <MuiSelect
                    v-model="inputs.periodType"
                    v-validate="{
                      isRequired: true,
                    }"
                    :options="filteredPeriods"
                    name="periodType"
                    label="Recurrence*"
                    class="w-100 mb-4"
                    placeholder="Select a recurrence"
                  />
                </div>
                <div class="col-md-12">
                  <MuiAlgoliaSelect
                    v-if="isAffectingToASalesOrder"
                    v-model="inputs.salesOrderUuid"
                    :title="({uuid}) => formatUuid(uuid)"
                    :filters="filterByContract(contractUuid)"
                    :index="ALGOLIA_INDEXES.fpSalesOrders"
                    label="Sales order"
                    name="salesOrderUuid"
                    path-value="uuid"
                    placeholder="Select sales order"
                    class="w-100 mb-4"
                  />
                  <template v-if="isPrivateUse">
                    <label class="d-block mb-2 emobg-body-small emobg-font-weight-bold">
                      Select if net amount is fixed or percentage
                    </label>
                    <ui-radio
                      :value="isPercentage"
                      :option="true"
                      name="netPriceTypeOpt2"
                      class="ml-3"
                      caption="% based on private use"
                      data-test-id="radio-percentage"
                      @changevalue="() => isPercentage = true"
                    />
                  </template>
                  <div
                    v-if="showUnitsField"
                    class="mb-4"
                  >
                    <MuiInputText
                      v-model.number="inputs.units"
                      v-validate.blur="{
                        isRequired: true,
                        isPositiveInteger: true,
                      }"
                      name="units"
                      label="Units*"
                      placeholder="Enter units affected"
                    />
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <MuiInputText
                        v-if="isPercentage && !isNonRevenue"
                        v-model.number="inputs.percentage"
                        v-validate.blur="{
                          isRequired: true,
                          isPattern: isNumeric,
                        }"
                        name="percentage"
                        class="w-100"
                        label="Value*"
                        placeholder="Management fee %"
                        data-test-id="percentage"
                      />
                      <MuiInputText
                        v-if="!isPercentage && !isNonRevenue"
                        v-model="inputs.amount"
                        v-validate.blur="{
                          isRequired: true,
                          isPattern: isNumeric,
                        }"
                        name="amount"
                        data-test-id="amount"
                        class="mb-2"
                        label="Net amount*"
                        placeholder="Net amount per unit"
                        @change="netAmountAddDecimal"
                      />
                    </div>
                    <div class="col-6">
                      <MuiInputText
                        v-if="!isNonRevenue"
                        v-model.number="inputs.vat"
                        v-validate.blur="{
                          isRequired: true,
                          isPattern: isNumeric,
                        }"
                        name="vat"
                        label="VAT*"
                        placeholder="VAT %"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #footer>
            <div class="d-flex align-content-center">
              <CancelButton
                data-test-id="servicefee-modal-cancel-button"
                @click="closeModal"
              />
              <SaveButtonDebounced
                :disabled="!areAllValid"
                :type="BUTTON_TYPES.submit"
                narrow
                data-test-id="servicefee-modal-save-button"
              >
                {{ serviceFee ? 'Save' : 'Add' }}
              </SaveButtonDebounced>
            </div>
          </template>
        </GenericModalComponent>
      </template>
    </MuiValidationWrapper>
  </ui-form>
</template>
