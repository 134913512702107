<script>
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import mapKeys from 'lodash/mapKeys';
import parseInt from 'lodash/parseInt';
import toString from 'lodash/toString';
import {
  DATE_FORMAT,
  flatObject,
  formatUtc,
  TIME_ZONE,
} from '@emobg/web-utils';
import { mapActions, mapMutations, mapState } from 'vuex';
import { BUTTON_TYPES } from '@emobg/vue-base';
import {
  MuiInputText,
  MuiSelect,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton, GenericModalComponent, LabelWithTooltip, SaveButtonDebounced,
} from '@/components';
import { countryCodeValidator } from '@/components/validators/countryCodeValidator';

import { NETD_LIST, PERIODS } from '../../Contracts/constants';

export default {
  name: 'InvoicePlanForm',
  components: {
    CancelButton,
    LabelWithTooltip,
    MuiInputText,
    MuiSelect,
    MuiTextarea,
    GenericModalComponent,
    MuiValidationWrapper,
    SaveButtonDebounced,
  },
  directives: {
    Validate,
  },
  props: {
    invoicePlanUuid: {
      type: String,
      default: undefined,
    },
    contractUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        firstInvoiceDate: '',
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleetInvoicing.invoicePlan, {
      isLoading: ({ STATUS }) => STATUS.LOADING,
      isLoaded: ({ STATUS }) => STATUS.LOADED,
      formStatusIsLoading: ({ FORM_STATUS }) => FORM_STATUS.LOADING,
      formStatusIsLoaded: ({ FORM_STATUS }) => FORM_STATUS.LOADED,
      invoicePlan: ({ invoicePlan }) => invoicePlan,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => get(state, 'operators.active.timezone', TIME_ZONE.default),
    }),
    modalTitle() {
      return this.invoicePlanUuid ? 'Edit invoice plan' : 'Create invoice plan';
    },
  },
  watch: {
    isLoaded(value) {
      if (value) {
        this.$emit('refresh-table');
        this.closeModal();
        this.notify({ message: 'Invoice plan saved successfully' });
      }
    },
    async formStatusIsLoaded() {
      await this.$nextTick();
      this.$refs.validationWrapper.validateAll();
    },
    invoicePlan(invoicePlan) {
      this.inputs = this.normalizeInvoicePlanValues(invoicePlan);
    },
  },
  created() {
    this.periods = PERIODS;
    this.netDaysList = NETD_LIST;
    this.countryCodeValidator = countryCodeValidator;
    this.BUTTON_TYPES = BUTTON_TYPES;

    if (this.invoicePlanUuid) {
      this.getInvoicePlan(this.invoicePlanUuid);
    }
  },
  destroy() {
    this.resetInvoicePlan();
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    ...mapActions(DOMAINS_MODEL.fleetInvoicing.invoicePlan, [
      'saveInvoicePlan',
      'getInvoicePlan',
      'resetInvoicePlan',
    ]),
    normalizeInvoicePlanValues(invoicePlan) {
      const normalizedInvoicePlan = mapKeys(flatObject(invoicePlan), (value, key) => camelCase(key));

      normalizedInvoicePlan.netDays = toString(
        get(normalizedInvoicePlan, 'netDays') || '',
      );

      return normalizedInvoicePlan;
    },
    onSubmit() {
      this.saveInvoicePlan({
        invoicePlanUuid: this.invoicePlanUuid,
        request: {
          ...this.inputs,
          firstInvoiceDate: formatUtc(get(this, 'inputs.firstInvoiceDate'), DATE_FORMAT.date, this.operatorTimezone),
          contractUuid: this.contractUuid,
          netDays: parseInt(this.inputs.netDays),
        },
      });
    },
    closeModal() {
      this.$emit('close-modal');
    },
  },
};
</script>
<template>
  <ui-form @submitform="onSubmit">
    <MuiValidationWrapper ref="validationWrapper">
      <template slot-scope="{ areAllValid }">
        <GenericModalComponent
          v-bind="$attrs"
          :header="{ title: modalTitle, isClosable: true }"
          :size="SIZES.medium"
          data-test-id="invoice-plan-modal"
          @modal-closed="closeModal"
        >
          <template #body>
            <div class="InvoicePlanFormComponent">
              <ui-loader
                v-if="formStatusIsLoading"
                relative
                data-test-id="loader"
              />
              <section v-else>
                <div class="row">
                  <div class="col-12 mb-4">
                    <h3>Invoice info</h3>
                  </div>
                  <div class="col-6">
                    <LabelWithTooltip
                      label="Invoice plan*"
                      tooltip="A serie suffix defining the final serie where the invoices will be created.
                    Example: invoice plan name QWER will generate the serie
                    `INV19/**QWER**-XXXX` (being XXXX the invoice number)."
                      class="mb-4"
                    >
                      <MuiInputText
                        v-model="inputs.billingReference"
                        v-validate.blur="{
                          isRequired: true,
                        }"
                        class="w-100"
                        data-test-id="invoice-plan-modal-billing-reference"
                        name="billingReference"
                        placeholder="Invoice plan"
                      />
                    </LabelWithTooltip>
                  </div>
                  <div class="col-6">
                    <LabelWithTooltip
                      label="First invoice date"
                      tooltip="Date when the first invoice will be issued."
                      class="mb-4"
                    >
                      <ui-datetimepicker
                        :date.prop="inputs.firstInvoiceDate"
                        :size="SIZES.small"
                        skiptime
                        data-test-id="invoice-plan-modal-invoice-date"
                        @datechanged="({ detail }) => inputs.firstInvoiceDate = detail"
                      />
                    </LabelWithTooltip>
                  </div>
                  <div class="col-6">
                    <LabelWithTooltip
                      label="Invoice cycle*"
                      tooltip="Recurrence of the invoices."
                      class="mb-4"
                    >
                      <MuiSelect
                        v-model="inputs.period"
                        v-validate="{
                          isRequired: true,
                        }"
                        :options="periods"
                        option-label="name"
                        option-value="code"
                        data-test-id="invoice-plan-modal-period"
                        name="period"
                        class="w-100"
                        placeholder="Select a period"
                      />
                    </LabelWithTooltip>
                  </div>
                  <div class="col-6">
                    <LabelWithTooltip
                      label="NetD (days)*"
                      tooltip="Defines the amount of days the customer will have to pay the
                    invoices, starting from the issue date."
                      class="mb-4"
                    >
                      <MuiSelect
                        v-model="inputs.netDays"
                        v-validate="{
                          isRequired: true,
                        }"
                        :options="netDaysList"
                        placeholder="Select days"
                        option-label="netD"
                        option-value="netD"
                        data-test-id="invoice-plan-modal-netdays"
                        name="netDays"
                        class="w-100"
                      />
                    </LabelWithTooltip>
                  </div>
                  <div class="col-12">
                    <LabelWithTooltip
                      label="Purchase order number (optional)"
                      tooltip="Number provided by the customer to relate the invoice with their purchase order."
                      class="mb-4"
                    >
                      <MuiInputText
                        v-model="inputs.purchaseOrderNumber"
                        class="w-100"
                        name="purchaseOrderNumber"
                        data-test-id="invoice-plan-modal-purchase-order-number"
                        placeholder="Purchase order number"
                      />
                    </LabelWithTooltip>
                  </div>
                  <div class="col-12">
                    <LabelWithTooltip
                      label="Invoice footer (optional)"
                      tooltip="Invoice footer for the invoice."
                      class="mb-4"
                    >
                      <MuiTextarea
                        v-model="inputs.footer"
                        class="w-100"
                        name="footer"
                        rows="1"
                        data-test-id="invoice-plan-modal-footer"
                        placeholder="Write invoice footer"
                      />
                    </LabelWithTooltip>
                  </div>
                  <div class="col-12 mb-4">
                    <h3>Customer info</h3>
                  </div>
                  <div class="col-6">
                    <LabelWithTooltip
                      label="Customer name*"
                      tooltip="Customer details for the invoice."
                      class="mb-4"
                    >
                      <MuiInputText
                        v-model="inputs.customerName"
                        v-validate.blur="{
                          isRequired: true,
                        }"
                        class="w-100"
                        placeholder="Customer name"
                        name="customerName"
                        data-test-id="invoice-plan-modal-customer-name"
                      />
                    </LabelWithTooltip>
                  </div>
                  <div class="col-6">
                    <LabelWithTooltip
                      label="Email address*"
                      tooltip="Customer details for the invoice."
                      class="mb-4"
                    >
                      <MuiInputText
                        v-model="inputs.customerEmail"
                        v-validate.blur="{
                          isRequired: true,
                        }"
                        type="email"
                        class="w-100"
                        placeholder="Enter email address"
                        name="customerEmail"
                        data-test-id="invoice-plan-modal-customer-email"
                      />
                    </LabelWithTooltip>
                  </div>
                  <div class="col-12">
                    <LabelWithTooltip
                      label="Fiscal number*"
                      tooltip="Customer details for the invoice."
                      class="mb-4"
                    >
                      <MuiInputText
                        v-model="inputs.customerCif"
                        v-validate.blur="{
                          isRequired: true,
                        }"
                        class="w-100"
                        name="customerCif"
                        placeholder="Enter fiscal number"
                        data-test-id="invoice-plan-modal-fiscal-number"
                      />
                    </LabelWithTooltip>
                  </div>
                  <div class="col-6">
                    <MuiInputText
                      v-model="inputs.customerAddressLine1"
                      v-validate.blur="{
                        isRequired: true,
                      }"
                      class="w-100"
                      label="Address*"
                      name="customerAddressLine1"
                      placeholder="Address line 1"
                      data-test-id="invoice-plan-modal-address-line1"
                    />
                  </div>
                  <div class="col-6">
                    <MuiInputText
                      v-model="inputs.customerAddressLine2"
                      class="w-100"
                      name="customerAddressLine2"
                      label="Floor, door, apartment..."
                      placeholder="Enter address"
                      data-test-id="invoice-plan-modal-address-line2"
                    />
                  </div>
                  <div class="col-6 mt-4">
                    <MuiInputText
                      v-model="inputs.customerAddressCity"
                      v-validate.blur="{
                        isRequired: true,
                      }"
                      class="w-100"
                      name="customerAddressCity"
                      label="City*"
                      placeholder="City"
                      data-test-id="invoice-plan-modal-city"
                    />
                  </div>
                  <div class="col-6 mt-4">
                    <MuiInputText
                      v-model="inputs.customerAddressPostalCode"
                      v-validate.blur="{
                        isRequired: true,
                      }"
                      class="w-100"
                      name="customerAddressPostalCode"
                      placeholder="Postal code"
                      label="Postal code*"
                      data-test-id="invoice-plan-modal-postal-code"
                    />
                  </div>
                  <div class="col-12 mt-4">
                    <MuiInputText
                      v-model="inputs.customerAddressCountry"
                      v-validate.blur="{
                        isRequired: true,
                        isPattern: countryCodeValidator,
                      }"
                      class="w-100"
                      name="customerAddressCountry"
                      label="Country*"
                      placeholder="Enter 2 letter country code"
                      caption="Example: ES, UK, DE, IT, FR"
                      data-test-id="invoice-plan-modal-country"
                    />
                  </div>
                  <LabelWithTooltip
                    class="mt-4 col-12"
                    label="Other details (optional)"
                    tooltip="Customer details for the invoice."
                  >
                    <MuiTextarea
                      v-model="inputs.customerOtherDetails"
                      class="w-100"
                      name="customerOtherDetails"
                      rows="2"
                      placeholder="Contact person's name, email, phone, department"
                      data-test-id="invoice-plan-modal-other-details"
                    />
                  </LabelWithTooltip>
                </div>
              </section>
            </div>
          </template>
          <template #footer>
            <div class="d-flex align-content-center">
              <CancelButton
                data-test-id="invoice-plan-modal-cancel"
                @click="closeModal"
              />
              <SaveButtonDebounced
                :disabled="!areAllValid"
                :type="BUTTON_TYPES.submit"
                narrow
                data-test-id="invoice-plan-modal-send"
              >
                {{ invoicePlanUuid ? 'Save' : 'Create' }}
              </SaveButtonDebounced>
            </div>
          </template>
        </GenericModalComponent>
      </template>
    </MuiValidationWrapper>
  </ui-form>
</template>
