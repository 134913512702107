<script>
import find from 'lodash/find';
import get from 'lodash/get';
import { MuiModal } from '@emobg/motion-ui/v1';
import { DATE_FORMAT, reformatDateTime, sentenceCase } from '@emobg/web-utils';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, ContentCellComponent, SaveButton } from '@/components';
import { JURIDIC_STATUS_COLORS } from '@/domains/Carsharing/Vehicles/Details/Setup/components/const/editVehicleComponents';
export default {
  components: {
    MuiModal,
    CancelButton,
    ContentCellComponent,
    SaveButton,
  },
  props: {
    salesOrderUuid: {
      type: String,
      required: true,
    },
    vehicleData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      startDate: undefined,
      endDate: undefined,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleetInvoicing.salesOrders, {
      salesOrderStatus: state => get(state, 'STATUS'),
    }),
  },
  created() {
    this.JURIDIC_STATUS_COLORS = JURIDIC_STATUS_COLORS;
    this.startDate = this.vehicleData.periodStart;
    this.endDate = this.vehicleData.periodEnd;
    this.modalConfig = {
      title: 'Edit vehicle',
      header: {
        class: 'pl-3',
        isClosable: true,
      },
    };
  },
  methods: {
    find,
    sentenceCase,
    ...mapActions(DOMAINS_MODEL.fleetInvoicing.salesOrders, [
      'patchSalesOrderVehicle',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    async editVehicle() {
      await this.patchSalesOrderVehicle({
        salesOrderUuid: this.salesOrderUuid,
        vehicleUuid: this.vehicleData.uuid,
        data: {
          startDate: reformatDateTime(this.startDate, DATE_FORMAT.date),
          endDate: reformatDateTime(this.endDate, DATE_FORMAT.date),
        },
      });
      if (!this.salesOrderStatus.ERROR) {
        this.notify({ message: 'Vehicle edited successfully' });
        this.$emit('close-modal');
      }
    },
  },
};
</script>
<template>
  <MuiModal
    v-bind="modalConfig"
    is-open
    @modal-closed="$emit('close-modal')"
  >
    <div slot="body">
      <div class="row">
        <ContentCellComponent
          :value="vehicleData.licensePlate"
          label="License plate"
          class="col-md-6 mb-3"
        />
        <ContentCellComponent
          :value="vehicleData.vehicleModel"
          label="Model"
          class="col-md-6 mb-3"
        />
      </div>
      <div class="row">
        <ContentCellComponent
          :value="vehicleData.csOperator || FALLBACK_MESSAGE.dash"
          label="CS operator"
          class="col-md-6"
        />
        <ContentCellComponent
          label="Juridic status"
          class="col-md-6"
        >
          <ui-badge
            :color="find(JURIDIC_STATUS_COLORS, (key, value) => vehicleData.juridicStatus === value)"
            :text="sentenceCase(vehicleData.juridicStatus)"
          />
        </ContentCellComponent>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <ui-datetimepicker
            :date.prop="startDate"
            :size="SIZES.small"
            label="Start date*"
            skiptime
            data-test-id="sales_order-start_date"
            @datechanged="({ detail }) => startDate = detail"
          />
        </div>
        <div class="col-6">
          <ui-datetimepicker
            :date.prop="endDate"
            :size="SIZES.small"
            label="End date*"
            skiptime
            data-test-id="sales_order-end_date"
            @datechanged="({ detail }) => endDate = detail"
          />
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="d-flex justify-content-end p-3"
    >
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('close-modal')"
      />
      <SaveButton
        :loading="salesOrderStatus.LOADING"
        :disabled="!startDate || !endDate"
        data-test-id="save-button"
        @click="editVehicle"
      />
    </div>
  </MuiModal>
</template>
